<template>
    <div class="Loss" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品名称">
                            <el-input v-model="form.goodsName" placeholder="请输入商品名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="统计日期">
                            <el-date-picker
                                placeholder="选择日期"
                                v-model="form.sumDateNum"
                                value-format="yyyyMMdd"
                                :clearable="true"
                                :editable="false"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                size="small"
                type="primary"
                @click="handleQuery"
                v-if="hasPrivilege('menu.report.goods.sales.trade.deptGoodsDayTradeSum.open')"
                >查询
            </el-button>
        </el-card>
        <p style="color: #dd6161">
            说明：商品每日交易退款汇总数据统计的是前一天同步到后台的退款数据并按照商品交易退款数量倒序排序，暂不支持统计当天交易退款汇总数据，如果数据延迟隔天同步，则数据会算到第二天。
        </p>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="420"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="统计日期" width="200" prop="sumDate" sortable :sort-method="sortSumDate" />
                <el-table-column label="机构" width="200" prop="deptName" sortable :sort-method="sortDeptName" />
                <el-table-column label="商品" width="300" prop="goodsName" sortable :sort-method="sortGoodsName" />
                <el-table-column label="商品交易退货数量" width="160" prop="totalCount" sortable>
                    <template slot-scope="scope">
                        <span>{{ scope.row.totalCount }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品总金额" width="160" prop="goodsSumMoney" sortable>
                    <template slot-scope="scope">
                        <span>{{ scope.row.goodsSumMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="退款总金额" width="160" prop="refundSumMoney" sortable>
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundSumMoney | money }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import EfPagination from 'components/EfPagination';
import Util from 'js/Util';

export default {
    name: 'DeptGoodsDayTradeRefundSum',
    components: {
        EfPagination,
    },
    data() {
        return {
            form: {
                deptCode: '',
                sumDateNum: '',
                goodsName: '',
                page: 1,
                limit: 100,
            },
            depts: [],
            tableData: [],
            total: 0,
        };
    },
    mounted() {
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        async queryList() {
            const rst = await this.$efApi.deptGoodsDayTradeRefundSumApi.pageList(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        sortSumDate(a, b) {
            return this.sort(a.sumDate, b.sumDate);
        },
        sortDeptName(a, b) {
            return this.sort(a.deptName, b.deptName);
        },
        sortGoodsName(a, b) {
            return this.sort(a.goodsName, b.goodsName);
        },
        sort(a, b) {
            if (Util.isEmpty(a)) {
                return -1;
            } else if (Util.isEmpty(b)) {
                return 1;
            } else {
                return String(a).localeCompare(String(b), 'zh');
            }
        },
    },
};
</script>
